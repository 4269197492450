#attachments-container-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.disabled-select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-action-button-container {
    display: flex;
    justify-content: space-between;
}

.form-action-button-container-left {
    width: 45%;
}

.form-action-button-container-left * {
    width: 100%;
}

.form-action-button-container-right {
    width: 50%;
}

.form-action-button-container-right * {
    width: 100%;
}

#attachment-data-row {
    border-bottom: 1px solid rgb(108, 111, 112);
}

.attachment-container {
    display: flex;
    justify-content: space-between;
    width: 15%;
}

.attachment-container-left {
    width: 85%;
}

.attachment-container-right {
    width: 15%;
    background: rgb(108, 111, 112);
    color: #ffff;
    border-radius: 4px;
    padding-left: 5%;
    padding-top: 0%;
    font-size: 12px;
    height: 50%;
    margin-top: 4%;
}

.card-top-container {
    display: flex;
    justify-content: space-between;
}

.card-top-container-left {
    width: 50%;
}

.card-top-container-left input {
    width: 100%;
}

.card-top-container-right {
    width: 22.5%;
}

.card-top-container-right div {
    float: right;
}

#status-color {
    height: 10px;
    width: 10px;
    border-radius: 2px;
    z-index: 1;
    position: absolute;
    top: 6.65%;
    right: 22%;
}

#support-ticket-received-at {
    color: rgb(108, 111, 112);
}

#support-ticket-status-container-right {
    width: 100%;
    padding-right: 2%;
    display: flex;
}

#support-ticket-status {
    float: right;
    color: rgb(108, 111, 112);
    font-weight: 500;
}

.set-input-width-assignee {
    text-align: center;
}

.comments-heading-container {
    width: 22%;
    display: flex;
    position: absolute;
    background: #ffff;
    top: -35px;
    height: 36px;
    left: -1px;
    padding-left: 5%;
    padding-top: 8px;
    border: 1px solid lightgray;
    border-bottom: 0;
    justify-content: space-between;
}

.other-comments-card {
    background: rgb(233, 233, 233);
}

.my-comments-card {
    background: #eeeefa;
}

.comments-top-container {
    display: flex;
    justify-content: space-between;
}

.comments-top-container-left {
    width: 40%;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}

.comments-top-container-left-first-letter {
    height: 30px;
    width: 30px;
    background: rgb(108, 111, 112);
    color: #ffff;
    border-radius: 50%;
    padding-left: 5%;
    padding-top: 1%;
}

.comments-top-container-left-full-name {
    width: 80%;
}

.comments-top-container-right {
    width: 40%;
}

.comments-top-container-right span {
    float: right;
}

.comments-second-container {
    display: flex;
}

.text-right {
    float: right;
    text-decoration: underline;
}

.text-right a {
    text-decoration: underline;
}

.status-select-dropdown {
    padding-left: 17.5%;
}

.status-select-dropdown:focus {
    outline: none;
    height: 100%;
}

.file-upload{
    display: none;
}

.clear-icon{
    padding-top: 5px;
}

.created-by-top-margin {
    margin-top: 50px;
}
