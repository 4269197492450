.links {
    display: flex;
    justify-content: flex-start;
    margin: 0.2em 4em;
    flex-wrap: wrap;
    cursor: pointer;
}

hr {
    border-top: 1.5px solid rgba(0,0,0,.35);
}

.alignNoData {
    text-align: center;
}