.btn-class {
    margin: 30px;
}

.table-month {
    border: 1px solid black;
}

.toggle-switch {
    margin-right: 20px;
}
.hour-width {
    width: 70px;
}
.weeks-table {
    margin-left: 100px;
}
.allocation-table {
    width: 100%;
}
.export-btn {
    margin-right: 5px;
}
