.dropdown-menu-filter {
    position: absolute;
    top: 8px !important;
    left: -158px !important;
    opacity: 1 !important;
    pointer-events: none;
    margin: 0px;
    z-index: 9999;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    width: 250px;
}

.sub-form .input-holder .span-item {
    top: 8px;
}

#popover-body-filter {
    width: 250px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

#popover-positioned-filter-left {
    position: absolute;
    top: 56px !important;
    left: auto;
    margin: 0px;
    right: 0px;
    bottom: auto;
    transform: translate(-131px, 85px) !important;
}

#popover-positioned-filter-left > .arrow {
    transform: translate(0px, 29px) !important; 
}

.add-workItem-button {
    margin-left: 2%;
    width: 100%;
    margin-right: 2%;
    border: 1px solid;
    background-color: #F4F4F3;
    border-radius: 30px;
    display: flex;
}

#workitem-input {
    border: 0;
    width: 95%;
    padding: 1%;
    background-color: #F4F4F3;
    border-radius: 30px;
    padding-left: 20px;
}

#workitem-input:focus {
    border: 0 !important;
    outline: -webkit-focus-ring-color auto 0px;
}

#workitem-input-task {
    border: 0;
    padding: 1%;
    background-color: #F4F4F3;
    border-radius: 30px;
    padding-left: 20px;
}

#workitem-input-task:focus {
    border: 0 !important;
    outline: -webkit-focus-ring-color auto 0px;
}

#workitem-input-subtask {
    border: 0;
    padding: 1%;
    background-color: #F4F4F3;
    border-radius: 30px;
    padding-left: 20px;
}

#workitem-input-subtask:focus {
    border: 0 !important;
    outline: -webkit-focus-ring-color auto 0px;
}

.fill-template-name {
    width: 94%;
}

