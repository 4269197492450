#myItemCard {
    border-top: 0px;
}

#button-nav {
    margin-left: auto;
}
#button-nav-project {
    margin-top: 10px;
    margin-left: auto;
}

.customActiveRoute {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff !important;
}