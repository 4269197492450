body {
    width: 100%;
    height: 100vh;
}

.main-container {
    width: 100%;
    min-width: 521px;
    margin: 3% auto;
    border: 0;
    height: 100%;
    margin-left: 3%;
    margin-right: 5%;
    margin-bottom: 0%;
}

.container-contents {
    display: flex;
    overflow-x: hidden;
}

button {
    width: inherit;
}

button.span-item {
    outline: none;
}

.form-control {
    height: 34px !important;
}

.cover-spin-without-padding {
    position: absolute;
    height: 91%;
    width: 100%;
    z-index: 1031;
    background: rgba(0, 0, 0, 0.41);
    top: 9%;
    left: 0%;
}

#loaderImage {
    position: absolute;
    top: 37%;
    right: 31%;
    height: 130px;
}

#dropdown-variants-secondary {
    display: flex;
    color: #495057 !important;
    font-weight: 400 !important;
}

#dropdown-variants-secondary-po {
    display: flex;
    background-color: white;
    color: #495057 !important;
    font-weight: 400 !important;
}

.pagination-report {
    display: flex;
    justify-content: center;
}
