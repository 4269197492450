.linkCard {
    height: 108px;
    width: 125px;
    margin: 9px 5px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out, box-shadow 0.25s ease-out, transform 0.25s ease-out;
    background-color: rgba(255,255,255,0.5);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-animation: top-to-down .75s;
    animation: top-to-down .75s;
    border-radius: 8px;
    transition: all 0.5s;
}

.linkCard:hover{
    background-color: rgba(255,255,255,1);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.specialLinkCard :hover {
    /* background-color: rgba(253, 13, 13, 1); */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

a {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.linkCard img, .specialLinkCard img {
    height: 35px;
    margin-top: 22px;
    margin-bottom: 15px;
}

.linkCard span ,.specialLinkCard span{
    text-align: center;
    font-size: 12px;
    color: #000000;
}

#dashboardCard {
    margin-right: 0px;
    width: 35px;
}

.specialLinkCard {
    height: 108px;
    width: 125px;
    margin: 9px 5px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out, box-shadow 0.25s ease-out, transform 0.25s ease-out;
    background-color: rgba(253, 13, 13, 0.5);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    -webkit-animation: top-to-down .75s;
    animation: top-to-down .75s;
    border-radius: 8px;
    transition: all 0.5s;
}

.linkBackgroundColor{
    background-color: rgba(255,255,255,0.5);
}

.specialLinkBackgroundColor{
    background-color: rgba(253, 13, 13, 0.5);
}