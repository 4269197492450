#avatar-comments {
    object-fit: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 0px;
}

.set-fontSize {
    font-size: 14px;
}

.set-padding {
    padding: 2%;
}

.set-date {
    float: right;
    margin-top: 12px;
}

.chat-height {
    min-height:  10px;
    max-height: 1000px;
    overflow-y: auto;
    background-color: white;
}

.my-comments {
    margin-left: auto;
}

.comment-color {
    background-color: #F6F6FE
}

.RichTextEditor__editor___1QqIU .public-DraftEditor-content {
    overflow: auto;
    min-height: 124px;
}

.tab-content {
    background-color: white;
}