.Loader {
    position: absolute;
    height: 91%;
    width: 100%;
    z-index: 1090;
    background: rgba(0, 0, 0, 0.41);
    top: 9%;
    left: 0%;
}
  
#loaderImage {
    position: absolute;
    top: 37%;
    right: 31%;
    height: 180px;
    animation: fade 2s infinite;
}

@keyframes fade { 
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.25;
    }
    
    50% {
        opacity: 0.5
    }

    75% {
        opacity: 0.75;
    }
  
    100% {
        opacity: 1;
    }
  }