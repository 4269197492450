.addPaddingLeft {
    padding-left: 5% !important;
}

.first-column {
    display: flex;
    max-width: 275px;
    word-break: break-all;
}

#onHover-row {
    cursor: pointer;
}

#mat-icon-arrow {
    visibility: visible;
}

.table-row {
    background-color: rgba(0, 0, 0, 0.075);
}
form {
    width: 100%;
}
#workitem-input-task {
    width: 100%;
}
.input-holder {
    position: relative;
}

.input-holder .span-item {
    position: absolute;
    right: 5px;
    margin: 0;
    top: 0;
    border: none;
    background: transparent;
}

.input-holder .span-item .material-icons {
    color: #8f8484;
}